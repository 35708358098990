@font-face {
  font-family: 'ProximaNovaBold';
  src: local('ProximaNova-Bold'), url('./fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaSemibold';
  src: local('ProximaNova-SemiBold'), url('./fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

html body {
  margin: 0;
  padding: 0;
  font-family: "ProximaNovaBold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
